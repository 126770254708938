import React from 'react';

const MobileAuth = ({ mobile, onChange }) => (
  <div className="push-top-lg">
    <label className="control-label" style={{ fontSize: '1.5rem' }}>
      {'Please enter your mobile number'}
    </label>
    <input
      type="text"
      className="form-control text-center"
      style={{ fontSize: '1.5rem' }}
      value={mobile}
      onChange={onChange}
    />
  </div>
);

export default MobileAuth;
