const { NODE_ENV } = process.env;

let config = {};

const { origin, hostname } = window.location;

if (NODE_ENV === 'development') {
  config = {
    OAUTH_LOGIN_URL: 'https://account.projectvectors.com.au/oauth/login',
    OAUTH_LOGOUT_URL: 'https://account.projectvectors.com.au/oauth/logout',
    OAUTH_CLIENT_ID: 'fb1a2aa7ff48f3eeaaa07485be7807043ac6420e0193448c37ec975d2d6faec8',
    OAUTH_CALLBACK_URL: `${origin}/auth/login`,
    API_URL: `http://${hostname}:3000/api/`,
  };
} else {
  config = {
    OAUTH_LOGIN_URL: 'https://account.projectvectors.com.au/oauth/login',
    OAUTH_LOGOUT_URL: 'https://account.projectvectors.com.au/oauth/logout',
    OAUTH_CLIENT_ID: 'fb1a2aa7ff48f3eeaaa07485be7807043ac6420e0193448c37ec975d2d6faec8',
    OAUTH_CALLBACK_URL: `${origin}/auth/login`,
    API_URL: 'https://pvapp.projectvectors.com.au/api/',
  };
  // config = {
  //   OAUTH_LOGIN_URL: 'https://pv-accounts-docker-dev.azurewebsites.net/oauth/login',
  //   OAUTH_LOGOUT_URL: 'https://pv-accounts-docker-dev.azurewebsites.net/oauth/logout',
  //   OAUTH_CLIENT_ID: 'fb1a2aa7ff48f3eeaaa07485be7807043ac6420e0193448c37ec975d2d6faec8',
  //   OAUTH_CALLBACK_URL: `${origin}/auth/login`,
  //   API_URL: 'https://pvapp-docker-dev.azurewebsites.net/api/',
  // };
}

export default config;
