const initialState = {
  project: null,
  site: '',
  report: '',
};

export default (state = initialState, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case 'state/LOAD':
      return payload;
    case 'state/UPDATE':
      return {
        ...state,
        ...payload,
      };
    case 'state/DELETE':
      return initialState;
    default:
      return state;
  }
};
