import React, { useState } from 'react';
import { connect } from 'react-redux';
import Alert from 'Shared/Alert';

import { stateMapping, dispatchMapping } from './mapping';

const PersonnelView = (props) => {
  const { subbie, onSign, showMobileView, alert, disabled } = props;

  const [person, updatePerson] = useState({ ...subbie });

  const isValid = () => {
    const result = (
      Boolean(person.mobile)
      && Boolean(person.name)
      && Boolean(person.businessName)
    );
    return result;
  };

  return (
    <div className="d-flex flex-fill justify-content-around flex-column align-items-center padding-bottom-xl">
      <div className="container">
        <div className="card shadow">
          <div className="card-header">
            <h2>Sub-contractor Personnel</h2>
          </div>
          <div className="card-body text-center">
            <Alert alert={alert} />

            <div className="form-group push-top-lg">
              <label className="control-label" style={{ fontSize: '1.5rem' }}>{'Full Name *'}</label>
              <input
                type="text"
                className="form-control text-center"
                style={{ fontSize: '1.5rem' }}
                value={person.name}
                onChange={e => updatePerson({ ...person, name: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label className="control-label" style={{ fontSize: '1.5rem' }}>{'Business Name *'}</label>
              <input
                type="text"
                className="form-control text-center"
                style={{ fontSize: '1.5rem' }}
                value={person.businessName}
                onChange={e => updatePerson({ ...person, businessName: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label className="control-label" style={{ fontSize: '1.5rem' }}>{'Mobile *'}</label>
              <input
                type="text"
                className="form-control text-center"
                style={{ fontSize: '1.5rem' }}
                value={person.mobile}
                onChange={e => updatePerson({ ...person, mobile: e.target.value })}
              />
            </div>

            <div className="push-top-lg text-center">
              <button
                type="button"
                className="btn btn-primary btn-wide w-50 m-auto"
                onClick={() => onSign({ ...props, subbie: person })('timeIn')}
                disabled={!person || disabled || !isValid()}
              >
                {'Sign In'}
              </button>
            </div>
          </div>
          <div className="card-footer text-center">
            <button
              type="button"
              className="btn btn-link text-muted"
              onClick={() => showMobileView()}
            >
              {'Go back'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(PersonnelView);
