import ajax from 'Shared/Ajax';
import { getUser } from 'Shared/User';
import { train, getPersonList, createPerson, addFace, detect, identify, getPerson, verify } from 'Shared/face-rec';

export const stateMapping = state => ({
  ...state.appState
});

export const dispatchMapping = dispatch => ({
  showSelector: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        role: null,
      },
    });
  },
  showMobileView: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        mobile: null,
      },
    });
  },
  onSubmitMobile: ({ project, report }) => async (mobile) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId } = getUser();
      const personnel = await ajax('get', `projects/${companyId}/${project._id}/site/${report._id}/personnel/?mobile=${mobile}`);

      dispatch({
        type: 'state/UPDATE',
        payload: {
          view: 'personnel',
          mobile,
          personnel,
          loading: false,
        },
      });
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
  onFaceDetect: (submitMobile) => async (image, tries) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: true,
          nextFace: false,
        },
      });

      const { companyId } = getUser();
      const person = await detect(image.dataURI);
      // const personList=await getPersonList(companyId)
      // const personId = face && face.faceId && await identify(face.faceId, companyId);
      // const person = personId && await getPerson(personId, companyId);
      if (!person?.data) {
        throw new Error('Face not recognised. Please try again.');
      }
      // const currentPerson=personList?.data?.filter(p=>p._id===person?.data.personId)[0]
      
      // alert(JSON.stringify(person,'currentPerson'))
      // const mobile = currentPerson.mobile;
      // alert(JSON.stringify(person))
      const mobile = person?.data?.mobile;

      await submitMobile(mobile);
      dispatch({
        type: 'state/UPDATE',
        payload: {
          tries: 0,
        },
      });
    } catch (err) {
      if (tries < 4) {
        setTimeout(() => {
          dispatch({
            type: 'state/UPDATE',
            payload: {
              nextFace: true,
              tries: tries + 1,
            },
          });
        }, 3000);
      }
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          alert: {
            css: 'alert-warning',
            message: tries < 4
              ? "Oops! We’re having trouble recognising your face. Please position yourself directly in front of the camera and try again. Your clear view helps us serve you better!"
              : 'Too many requests. Please go back and try again.',
          },
        },
      });
    }
  },
  onCreatePerson: async (mobile) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: true,
        },
      });
      const { companyId } = getUser();

      const person = await verify(mobile)

      let personId = person && person?._id;

      if (!personId) {
        console.log('Person not found, creating the person');
        // personId = await createPerson(companyId, mobile);
        console.log('New Person created.', personId);
      }

      dispatch({
        type: 'state/UPDATE',
        payload: {
          personId,
          alert: null,
        },
       
      });
      return true;
    } catch (err) {
 
      let message='Creating person failed. Mobile number should be valid.'
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message,
          },
        },
      });
      return false;
    } finally {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
        },
      });
    }
  },
  onFaceRegister: async (mobile, image, personId, faces) => {
    await dispatch({
      type: 'state/UPDATE',
      payload: {
        nextFace: false,
      },
    });

    try {
      // const { companyId } = getUser();
      // alert( personId)
      console.log(personId,'personId')
      console.log(image,' image.dataBinary')
      
      await addFace(personId, image.dataURI);
      setTimeout(() => {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            nextFace: faces < 5,
            alert: null,
          },
        });
      }, 1000);
      return true;
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            nextFace: true,
            alert: {
              css: 'alert-warning',
              message: `Face not detected by Azure. Please keep trying.`,
            },
          },
        });
      }, 1000);
      return false;
    }
  },
  onUploadFaces: async (mobile, faces, done,faceId) => {
    let imageIndex = 0;

    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-info',
            message: 'Taining model...',
          },
        },
      });
      // alert(JSON.stringify(faces))
      // alert(faceId)
      // console.log(faces)
      // await addFace(faceId,faces)
      // const { companyId } = getUser();
      // await train(companyId);
      await done();
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: `Uploading image ${imageIndex} failed. ${err.message}`,
          },
        },
      });
    } finally {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
        },
      });
    }
  },
  onSign: ({ project, report, personnel }) => async (type) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId, company } = getUser();
      const data = {
        name: personnel.name,
        businessName: company.businessName,
        mobile: personnel.mobile,
        personnelId: personnel._id,
      };
      await ajax('put', `projects/${companyId}/${project._id}/site/${report._id}/personnel/${type}`, data);

      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          disabled: true,
          alert: {
            css: 'alert-success',
            message: `Signed ${type === 'timeIn' ? 'In' : 'Out'} Successfully.`,
          },
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: null,
            mobile: null,
            role: null,
            alert: null,
            disabled: false,
          },
        });
      }, 1500);
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
});
