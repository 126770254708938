import React from 'react';
import Face from 'Shared/Face';

const FaceAuth = ({ nextFace, onDetect }) => (
  <div className="push-top-lg">
    <label className="control-label" style={{ fontSize: '1.5rem' }}>
      {'Please look directly at the camera and hold still'}
    </label>

    <div className="push-top-lg text-center">
      <Face
        nextFace={nextFace}
        onDetect={onDetect}
      />
    </div>
  </div>
);

export default FaceAuth;
