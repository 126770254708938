const userInfo = localStorage.user;
export const user = userInfo && JSON.parse(userInfo);

const event = new Event('user_updated');

export const getUser = () => (
  localStorage.user && JSON.parse(localStorage.user)
);

export const setUser = (__user) => {
  localStorage.user = JSON.stringify(__user);

  window.dispatchEvent(event);
};

export const updateUser = (update) => {
  const newUser = {
    ...user,
    ...update,
  };

  localStorage.user = JSON.stringify(newUser);
};

export const deleteUser = () => {
  localStorage.user = null;
};

export const isInTeam = (project = {}, teamId) => {
  if (!user.teams || user.teams.length === 0) return false;

  return Boolean(user.teams.find(t => t.projectId === project._id && t.teamId === teamId));
};

export const adminRoles = ['company_admin'];
export const manageRoles = [...adminRoles, 'company_director', 'company_office'];
export const companyRoles = [...manageRoles, 'company_accountant'];
export const accountingRoles = [...adminRoles, 'company_director', 'company_accountant'];
export const projectRoles = [...manageRoles, 'company_staff'];
export const estimatorRoles = [...manageRoles, 'company_estimator'];
export const manageTeams = ['project_manager', 'contract_admin'];
export const documentTeams = [...manageTeams, 'subcontractor', 'client', 'site_supervisor'];
export const salaryRoles = ['company_admin', 'company_accountant', 'company_director'];
