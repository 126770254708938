import _ from 'lodash';
import ajax from 'Shared/Ajax';
import { getUser } from 'Shared/User';
import { saveData } from 'Shared/storage';
import { getPersonGroups, createPersonGroup } from 'Shared/face-rec';

export const stateMapping = state => ({
  reports: state.reports,
  projects: state.projects,
  sites: state.sites,
  project: state.appState.project,
  site: state.appState.site,
  reportId: state.appState.reportId,
  report: state.appState.report,
  loading: state.appState.loading,
  alert: state.appState.alert,
  page: state.appState.page,
});

export const dispatchMapping = (dispatch) => ({
  loadProjects: async () => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        }
      });

      const { companyId, company } = getUser();

      // alert("ddd")
      const projects=await ajax('get', `projects/${companyId}/list`)
      // const [projects, groups] = await Promise.all([
      //   ajax('get', `projects/${companyId}/list`),
      //   getPersonGroups(),
      // ]);

      // if (!groups.find(g => g.personGroupId === companyId)) {
        // createPersonGroup(companyId, company.businessName);
      // }

      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
        }
      });
      dispatch({
        type: 'projects/LOAD',
        payload: projects,
      });
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          alert: {
            css: 'alert-warning',
            message: err.message || err.data || 'Unknown Error.',
          },
        }
      });
    }
  },
  onSelectProject: ({ projects }) => async (projectId) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        }
      });

      const { companyId } = getUser();
      const project = projects.find(p => p._id === projectId);
      const reports = await ajax('get', `projects/${companyId}/${project._id}/site/today`);

      const sites = _.uniq(reports.map(r => r.site));

      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          project,
          site: sites.length === 1 ? sites[0] : '',
          reportId: reports.length === 1 ? reports[0]._id : '',
        }
      });
      dispatch({
        type: 'reports/LOAD',
        payload: reports,
      });
      dispatch({
        type: 'sites/LOAD',
        payload: sites,
      });
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          alert: {
            css: 'alert-warning',
            message: err.message || err.data || 'Unknown Error.',
          },
        }
      });
    }
  },
  onSelect: async (prop, value) => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        loading: false,
        [prop]: value,
      }
    });
  },
  onStart: ({ project, site, reports, reportId }) => () => {
    const report = reports.find(r => r._id === reportId);

    saveData('state', { project, site, report });

    dispatch({
      type: 'state/UPDATE',
      payload: {
        report,
        page: 'time-in',
      }
    });
  },
})