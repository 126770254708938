/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { getUser } from './Shared/User';
import LoginPage from './Pages/Login';
import HomePage from './Pages/Home';
import TimeInPage from './Pages/TimeIn';

const user = getUser();

const PrivateRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => (
      user
        ? children
        : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
    )}
  />
);

const App = () => (
  <Router>
    <Switch>
      <Route path="/auth/login" component={LoginPage} />
      <PrivateRoute path="/" exact>
        <HomePage />
      </PrivateRoute>
      <PrivateRoute path="/time-in" exact>
        <TimeInPage />
      </PrivateRoute>
    </Switch>
  </Router>
);

export default App;
