import React, { createRef } from 'react';
import * as faceapi from 'face-api.js';

let video;
let streaming = false;
const ACCURACY = 0.8;

const startVideo = () => {
  if (navigator.getMediaDevices) {
    navigator.getMediaDevices.getUserMedia(
      { video: {} },
      stream => video.srcObject = stream,
      err => window.alert(`navigator.getMediaDevices.getUserMedia:: ${err.message}`),
    );
  } else if (navigator.mediaDevices) {
    navigator.mediaDevices.getUserMedia(
      { video: { facingMode: "user" }, audio: false },
    )
      .then(function (stream) {
        video.srcObject = stream;
      })
      .catch(function (err) {
        window.alert(`navigator.mediaDevices.getUserMedia:: ${err.message}`);
      });
  } else {
    window.alert('Camera Access Not Supported');
  }
};

const stopVideo = () => {
  console.log('Stopping');
  streaming = false;

  const stream = video.srcObject;
  if (stream && stream.getTracks) {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  }
};

function arrayBuffer() {
  // this: File or Blob
  return new Promise((resolve) => {
    let fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsArrayBuffer(this);
  })
}

const getBinary = canvas => new Promise((resolve, reject) => {
  canvas.toBlob(async (blob) => {
    if (!blob) reject('Invalid Blob');

    File.prototype.arrayBuffer = File.prototype.arrayBuffer || arrayBuffer;
    Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || arrayBuffer;

    const buffer = await blob.arrayBuffer();
    resolve(buffer);
  })
});

const takePicture = async () => {
  video.pause();

  var canvas = document.createElement('canvas');
  canvas.width = video.clientWidth;
  canvas.height = video.clientHeight;
  var ctx = canvas.getContext('2d');

  //draw image to canvas. scale to target dimensions
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  //convert to desired file format
  var dataURI = canvas.toDataURL('image/jpeg');
  var dataBinary = await getBinary(canvas);

  video.play();

  return {
    dataURI,
    dataBinary,
  };
};

const startStreaming = async (detected) => {
  if (!streaming) return;

  try {
    const face = await faceapi.detectSingleFace(
      video,
      new faceapi.TinyFaceDetectorOptions(),
    );

    if (face) {
      const { score } = face;
      if (score >= ACCURACY) {
        console.log('****** FACE DETECTED ******');

        const image = await takePicture();
        detected(image);
      } else {
        setTimeout(() => {
          startStreaming(detected);
        }, 250);
      }
    } else {
      setTimeout(() => {
        startStreaming(detected);
      }, 2000);
    }
  } catch (err) {
    console.log(err.message);
  }
};

class MobileAuth extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextFace: false,
    };

    this.videoRef = createRef();
  }

  componentDidMount() {
    video = this.videoRef.current;
    video.removeAttribute("controls");

    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
    ])
      .then(startVideo)
      .catch(err => console.log(err.message));
  }

  componentWillUnmount() {
    stopVideo();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.nextFace !== state.nextFace) {
      if (props.nextFace) {
        if (!streaming) {
          streaming = true;
        }
        startStreaming(props.onDetect)
      }
      return {
        nextFace: props.nextFace,
      };
    }

    return null;
  }

  render() {
    const { onDetect } = this.props;

    return (
      <video
        ref={this.videoRef}
        width="100%"
        className="border mt-2"
        style={{
          maxWidth: 640,
          maxHeight: 480,
        }}
        autoPlay
        muted
        playsInline
        controls
        onPlay={() => {
          if (!streaming) {
            streaming = true;
            startStreaming(onDetect)
          }
        }}
      />
    );
  }
}

export default MobileAuth;
