import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Alert from 'Shared/Alert';
import { stateMapping, dispatchMapping } from './mapping';

class Login extends PureComponent {
  componentDidMount() {
    const { onLoad } = this.props;

    onLoad();
  }

  render() {
    const { alert, error, response, onLogout } = this.props;
    return (
      <div className="container padding-lg padding-top-xl">
        <div className="card shadow-sm no-border rounded">
          <div className="card-header">
            <h1>PVManager Login</h1>
          </div>
          <div className="card-body padding-xl text-center">
            {!error && <Alert alert={alert} />}
            {error && <h2 className="text-danger">{alert.message}</h2>}
            {response && <h5 className="text-middle-light push-top-md">{`Server response: ${response}`}</h5>}
          </div>
          {error && (
            <div className="card-footer text-right">
              <button
                type="button"
                className="btn btn-lg btn-primary btn-wide"
                onClick={() => onLogout()}
              >
                {'Go back and try again'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(stateMapping, dispatchMapping)(Login);
