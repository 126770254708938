import React from 'react';

const BreadCrumb = ({ project, report, site }) => {
  return (
    <nav aria-label="breadcrumb" style={{ minHeight: 60, maxHeight: 70, overflow: 'hidden' }}>
      <ol className="breadcrumb bg-white" style={{ fontSize: '1.4rem' }}>
        <li className="breadcrumb-item">
          <a href="/">
            <img src="https://pvapp.projectvectors.com.au/images/logo-24-dark.png" alt="" style={{ height: 24 }} />
          </a>
        </li>
        <li className="breadcrumb-item active">{project ? project.name : 'Project'}</li>
        <li className="breadcrumb-item active">{site || 'Site'}</li>
        <li className="breadcrumb-item active">{report ? report.name : 'Report'}</li>
        <li className="breadcrumb-item active ml-auto no-bullet text-muted">{'v 1.2.5'}</li>
      </ol>
    </nav>
  );
};

export default BreadCrumb;
