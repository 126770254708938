import React, { useState } from 'react';
import Face from 'Shared/Face';

const FaceRegister = ({ nextFace, onDetect, onUpload, onDone, onMobileChange: onChange, mobile, onCreatePerson, personId }) => {
  const [stream, updateStream] = useState(false);
  const [faces, updateFaces] = useState([]);

  return (
    <>
      <div className="push-top-md">
        <label className="control-label" style={{ fontSize: '1.5rem' }}>
          {'Please enter your mobile number'}
        </label>
        <input
          type="text"
          className="form-control text-center"
          style={{ fontSize: '1.5rem' }}
          value={mobile}
          onChange={onChange}
          disabled={stream}
        />
      </div>

      {!stream && (
        <div className="push-top-md text-center">
          <button
            type="button"
            className="btn btn-primary btn-wide w-50 m-auto"
            onClick={() => {
              // updateStream(true)
              onCreatePerson(mobile)
                .then((person) => person && updateStream(true));
            }}
            disabled={!mobile}
          >
            {'Next'}
          </button>
        </div>
      )}

      {stream && (
        <>
          <div className="push-top-md">
            <label className="control-label" style={{ fontSize: '1.5rem' }}>
              {'Please look directly at the camera and hold still'}
            </label>

            <div className="push-top-md text-center">
              <Face
                nextFace={nextFace}
                onDetect={(image) => {
                  // updateFaces([...faces, image])
                  onDetect(mobile, image, personId, faces.length + 1)
                    .then((detected) => detected && updateFaces([...faces, image]));
                }}
              />
            </div>
          </div>

          <div className="push-top-md">
            {faces.map((face, index) => (
              <img
                key={index}
                src={face.dataURI}
                style={{ width: '15%', margin: '2.5%' }}
                alt=""
              />
            ))}
          </div>

          {faces.length > 4 && (
            <div className="push-top-md text-center">
              <button
                type="button"
                className="btn btn-primary btn-wide w-50 m-auto"
                onClick={() => onUpload(mobile, faces, onDone,personId)}
              >
                {'Submit'}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FaceRegister;
