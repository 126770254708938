import React from 'react';
// import moment from 'moment';
import { connect } from 'react-redux';
import { stateMapping, dispatchMapping } from './mapping';

const styles = {
  control: {
    fontSize: '1.5rem',
  },
  label: {
    fontSize: '1.3rem',
  },
  button: {
    fontSize: '2rem',
  }
};

const Site = (props) => {
  const { projects = [], onSelectProject, project, sites = [], onSelect, site, reports = [], reportId, onStart } = props;

  return (
    <div className="push-top-lg">
      <div className="form-group push-right-lg push-left-lg push-bottom-xl">
        <select
          className="form-control"
          style={styles.control}
          value={project ? project._id : ''}
          onChange={e => onSelectProject(props)(e.target.value)}
        >
          <option value="">Select Project</option>
          {projects.map(p => (
            <option key={p._id} value={p._id}>{`${p.code} - ${p.name}`}</option>
          ))}
        </select>
      </div>

      <div className="form-group push-right-lg push-left-lg push-bottom-xl">
        <select
          className="form-control"
          style={styles.control}
          value={site || ''}
          onChange={e => onSelect('site', e.target.value)}
        >
          <option value="">Select Site</option>
          {sites.map(s => (
            <option key={s} value={s}>{s}</option>
          ))}
        </select>
      </div>

      <div className="form-group push-right-lg push-left-lg push-bottom-xl">
        <select
          className="form-control"
          style={styles.control}
          value={reportId || ''}
          onChange={e => onSelect('reportId', e.target.value)}
        >
          <option value="">Select Report</option>
          {reports.filter(r => r.site === site).map(r => (
            <option key={r._id} value={r._id}>
              {r.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group push-right-xl push-left-xl push-bottom-lg">
        <button
          type="button"
          className="btn btn-block btn-lg btn-primary"
          onClick={() => onStart(props)()}
          style={styles.button}
          disabled={!reportId}
        >
          {'Start'}
        </button>
      </div>

    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(Site);
