const initialState = [];

export default (state = initialState, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case 'reports/LOAD':
      return payload;
    case 'reports/ADD':
      return [...state, payload];
    case 'reports/UPDATE':
      return state.map((report) => (
        report._id === payload._id
          ? payload
          : report
      ));
    case 'reports/DELETE':
      return state.filter((report) => report._id !== payload);
    case 'reports/CLEAR':
      return initialState;
    default:
      return state;
  }
};
