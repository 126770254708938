import React from 'react';
import { connect } from 'react-redux';

import MobileView from './MobileView';
import PersonnelView from './PersonnelView';
import { stateMapping, dispatchMapping } from './mapping';

const Contractor = ({ view }) => {
  return (
    <div className="d-flex flex-fill align-items-center text-center padding-bottom-xl">
      {view === 'personnel'
        ? <PersonnelView />
        : <MobileView />
      }
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(Contractor);