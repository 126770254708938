import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ProgressOverlay from 'Shared/ProgressOverlay';
import { getUser } from 'Shared/User';

import { stateMapping, dispatchMapping } from './mapping';
import BreadCrumb from './BreadCrumb';
import Selector from './Selector';
import Contractor from './Contractor';
import Subbie from './Subbie';
import Visitor from './Visitor';
import Tools from './Tools';

class TimeInPage extends React.PureComponent {
  componentDidMount() {
    const { loadState } = this.props;

    loadState();
  }

  render() {
    const { page, loading, role } = this.props;
    const user = getUser();

    if (page === '/') {
      return <Redirect to={page} push={false} />;
    }

    return (
      <div className="d-flex flex-fill flex-column">
        <BreadCrumb {...this.props} />
        <div className="flex-fill d-flex">
          {loading && <ProgressOverlay />}

          {!role && <Selector user={user} {...this.props} />}

          {role === 'contractor' && <Contractor />}
          {role === 'subbie' && <Subbie />}
          {role === 'visitor' && <Visitor />}
          {role === 'tools' && <Tools />}

        </div>
      </div>
    );
  }
}

export default connect(stateMapping, dispatchMapping)(TimeInPage);
