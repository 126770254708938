import React from 'react';
import { connect } from 'react-redux';

import MobileView from './MobileView';
import SubbieView from './SubbieView';
import SignupView from './SignupView';
import { stateMapping, dispatchMapping } from './mapping';

const Contractor = ({ view }) => {
  return (
    <div className="d-flex flex-fill align-items-center text-center padding-bottom-xl">
      {(!view || view === 'mobile') && <MobileView />}
      {view === 'subbie' && <SubbieView />}
      {view === 'signup' && <SignupView />}
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(Contractor);