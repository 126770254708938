import ajax from 'Shared/Ajax';
import { setUser, deleteUser } from 'Shared/User';
import config from 'config';

const {
  OAUTH_LOGIN_URL,
  OAUTH_CLIENT_ID,
  OAUTH_CALLBACK_URL,
} = config;

const loginUrl = `${OAUTH_LOGIN_URL}?clientId=${OAUTH_CLIENT_ID}&callbackUrl=${OAUTH_CALLBACK_URL}`;

export const stateMapping = state => ({
  alert: state.appState.alert,
  progress: state.appState.progress,
  error: state.appState.error,
  response: state.appState.response,
});

export const dispatchMapping = dispatch => ({
  onLoad: async () => {
    try {
      dispatch({
        type: 'state/UPDATE', payload: {
          progress: true,
          alert: {
            css: 'alert-info',
            message: 'Logging you in...',
            progress: true,
          },
          error: false,
        }
      });

      const params = new window.URLSearchParams(window.location.href.split('?')[1]);
      const code = params.get('code');

      if (!code) {
        window.location.href = loginUrl;
        return;
      }

      const user = await ajax('get', `auth/verify-oauth/${code}`);

      dispatch({
        type: 'state/UPDATE', payload: {
          progress: false,
          user,
          alert: {
            css: 'alert-success',
            message: 'Login was successful. Redirecting...',
          },
          error: false,
        }
      });

      setUser(user);

      window.location.href = '/';
    } catch (err) {
      dispatch({
        type: 'state/UPDATE', payload: {
          progress: false,
          alert: {
            css: 'alert-warning',
            message: 'Something went wrong. Please try again.',
          },
          response: err.message,
          error: true,
        }
      });
    }
  },
  onLogout: async () => {
    try {
      await ajax('post', '/auth/logout');
      deleteUser();
      window.location.href = loginUrl;
    } catch (err) {
      window.alert(err.message || err.data || 'Unknown Error.');
    }
  },
});