import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Alert from 'Shared/Alert';

import { stateMapping, dispatchMapping } from '../mapping';
import MobileAuth from './Mobile';
import FaceAuth from './Face';
import FaceRegister from './FaceRegister';

let timer = null;
let t = null;

const MobileView = (props) => {
  const [mobile, setMobile] = useState('');
  const {
    onSubmitMobile, onFaceDetect, onFaceRegister, onUploadFaces, onCreatePerson,
    showSelector, alert, nextFace, tries = 0, personId,
  } = props;
  const [now, updateNow] = useState(new Date().toTimeString().substr(0, 8));
  const [authType, updateAuthType] = useState('');

  useEffect(() => {
    if (!timer) {
      timer = setInterval(() => {
        updateNow(new Date().toTimeString().substr(0, 8));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
      timer = null;
    };
  });

  useEffect(() => {
    // clearTimeout(t);
    if (!authType) {
      t = setTimeout(() => {
        updateAuthType('face');
      }, 2500);
    }

    return () => clearTimeout(t);
  }, [authType, updateAuthType]);

  return (
    <div className="d-flex flex-fill justify-content-around flex-column align-items-center padding-bottom-xl">
      <div className="container">
        <div className="card shadow">
          <div className="card-header">
            <h2 className="float-right">{now}</h2>
            <h2>Company Personnel</h2>
          </div>
          <div className="card-body text-center">
            <Alert alert={alert} />

            {!authType && (
              <div className="push-top-lg text-center">
                <h3 className="push-bottom-lg">Loading...</h3>
                {/* <h3 className="push-bottom-lg">Loading Camera...</h3> */}
                <button
                  type="button"
                  className="btn btn-light btn-wide btn-block w-sm-50 m-auto"
                  onClick={() => updateAuthType('mobile')}
                >
                  <i className="far fa-mobile mr-2" />
                  {'Sign in using Mobile Number'}
                </button>
              </div>
            )}

            {authType === 'mobile' && (
              <>
                <MobileAuth onChange={e => setMobile(e.target.value)} mobile={mobile} />

                <div className="push-top-lg text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-wide btn-block w-sm-50 m-auto"
                    onClick={() => onSubmitMobile(props)(mobile)}
                    disabled={!mobile}
                  >
                    {'Submit'}
                  </button>
                </div>

                {/* <div className="push-top-lg text-center">
                  <button
                    type="button"
                    className="btn btn-light btn-wide btn-block w-sm-50 m-auto"
                    onClick={() => updateAuthType('face')}
                  >
                    <i className="far fa-smile mr-2" />
                    {'Switch to Face Recognition'}
                  </button>
                </div> */}
              </>
            )}

            {authType === 'face' && (
              <>
                <FaceAuth
                  nextFace={nextFace}
                  onDetect={image => onFaceDetect(onSubmitMobile(props))(image, tries)}
                /> 

                <div className="push-top-lg text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-wide btn-block w-sm-50 m-auto"
                    onClick={() => updateAuthType('face-register')}
                  >
                    <i className="far fa-smile mr-2" />
                    {'Register a new face'}
                  </button>
                </div>

                <div className="push-top-lg text-center">
                  <button
                    type="button"
                    className="btn btn-light btn-wide btn-block w-sm-50 m-auto"
                    onClick={() => updateAuthType('mobile')}
                  >
                    <i className="far fa-mobile mr-2" />
                    {'Sign in using Mobile Number'}
                  </button>
                </div>
              </>
            )}

            {authType === 'face-register' && (
              <>
                <FaceRegister
                  nextFace={nextFace}
                  mobile={mobile}
                  personId={personId}
                  onMobileChange={e => setMobile(e.target.value)}
                  onDetect={onFaceRegister}
                  onUpload={onUploadFaces}
                  onCreatePerson={onCreatePerson}
                  onDone={() => onSubmitMobile(props)(mobile)}
                />

                <div className="push-top-lg text-center">
                  <button
                    type="button"
                    className="btn btn-light btn-wide btn-block w-sm-50 m-auto"
                    onClick={() => updateAuthType('mobile')}
                  >
                    <i className="far fa-mobile mr-2" />
                    {'Sign in using Mobile Number'}
                  </button>
                </div>
              </>
            )} 

          </div>
          <div className="card-footer text-center">
            <button
              type="button"
              className="btn btn-link text-muted"
              onClick={() => showSelector()}
            >
              {'Go back'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(MobileView);
