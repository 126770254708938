import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Alert from 'Shared/Alert';
import Navbar from './Navbar.js';
import Site from './Site';
import ProgressOverlay from 'Shared/ProgressOverlay';

import { stateMapping, dispatchMapping } from './mapping';

class HomePage extends React.PureComponent {
  componentDidMount() {
    const { loadProjects } = this.props;

    loadProjects();
  }

  render() {
    const { reportId, page, alert, loading } = this.props;

    if (reportId && page) {
      return <Redirect to={page} push={false} />;
    }

    return (
      <div className="d-flex flex-fill overflow-auto" style={{ paddingTop: '7rem' }}>
        <Navbar />
        <div className="container text-center">
          <div className="card shadow-sm no-border">
            <div className="card-header">
              <h1 className="d-block title-highlight push-bottom-md" style={{ fontSize: '2.5rem' }}>PVManager Timesheet</h1>
              <h2 style={{ fontSize: '2rem' }}>Site Supervisor Panel</h2>
            </div>
            <div className="card-body">
              {loading && <ProgressOverlay />}
              <Alert alert={alert} />
              <Site />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(stateMapping, dispatchMapping)(HomePage);
