import React from 'react';
import {
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Navbar as NavbarComponent,
  Nav,
  NavItem,
  NavbarToggler,
} from 'reactstrap';
import { getUser, deleteUser } from 'Shared/User';
import ajax from 'Shared/Ajax';
import Avatar from 'Shared/Avatar';
import config from 'config';


const {
  OAUTH_LOGOUT_URL,
  OAUTH_CLIENT_ID,
  OAUTH_CALLBACK_URL,
} = config;

const logoutUrl = `${OAUTH_LOGOUT_URL}?clientId=${OAUTH_CLIENT_ID}&callbackUrl=${OAUTH_CALLBACK_URL}`;


const logout = async (e) => {
  e.preventDefault();

  try {
    await ajax('post', '/auth/logout');
    deleteUser();
    window.location.href = logoutUrl;
  } catch (err) {
    window.alert(err.message || err.data || 'Unknown Error.');
  }
};

class UserDropdown extends React.PureComponent {
  render() {
    const user = getUser();
    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav style={{ padding: '0 8px', maxWidth: 250 }} className="text-right d-none d-sm-block">
            <span className="d-block text-white">{user.name}</span>
            <span className="d-block">{user.company.name}</span>
          </DropdownToggle>
          <DropdownToggle nav className="d-block d-sm-none">
            {`${user.name} (${user.company.businessName})`}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="#" onClick={e => logout(e)}>Logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem className="d-none d-sm-block">
          <Avatar
            className="navbar-logo"
            name={user.company.businessName}
            src={user.company.logoUrl}
            containMode={user.company.logoContainMode}
            size={32}
            round={'15%'}
            color="#cfd8dc"
            fgColor="#212121"
          />
        </NavItem>
      </>
    );
  }
}

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;

    return (
      <NavbarComponent color="dark" dark expand="sm" fixed="top">
        <NavbarBrand href="//projectvectors.com.au" target="_blank">
          <img src="https://pvapp.projectvectors.com.au/images/logo-48-light.png" alt="PVManager" className="d-inline-block" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UserDropdown />
          </Nav>
        </Collapse>
      </NavbarComponent>
    );
  }
}

export default Navbar;
