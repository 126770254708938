import ajax from 'Shared/Ajax';
import { getUser } from 'Shared/User';

export const stateMapping = state => ({
  loading: state.appState.loading,
  report: state.appState.report,
  project: state.appState.project,
  alert: state.appState.alert,
  role: state.appState.role,
  view: state.appState.view,
  code: state.appState.code,
  tool: state.appState.tool,
  disabled: state.appState.disabled,
  scanner: state.appState.scanner,
});

export const dispatchMapping = dispatch => ({
  showSelector: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        role: null,
      },
    });
  },
  showScanView: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        code: null,
      },
    });
  },
  onSubmitCode: ({ project, report }) => async (code) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId } = getUser();
      const tool = await ajax('get', `projects/${companyId}/${project._id}/site/${report._id}/tools/?code=${code}`);

      if (!tool) {
        throw new Error('Tool Not Found.');
      } else {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: 'tool',
            code,
            tool,
            loading: false,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
  onSign: ({ project, report, tool }) => async (type) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId } = getUser();
      const result = await ajax(
        type === 'timeIn' ? 'post' : 'delete',
        `projects/${companyId}/${project._id}/site/${report._id}/tools/${tool.code}`,
      );

      // update tools on Resources
      const logger = console;
      const { currentLocation, currentPersonnel } = result;
      const data = {
        currentLocation,
        currentPersonnel,
        status: type === 'timeIn' ? 'Signed in' : 'Signed out',
        refId: result.id,
      };
      ajax('post', `c/${companyId}/tools/${result.toolId}/history`, data)
        .then(() => logger.log('Tools updated.'))
        .catch(err => logger.log(`Updating tools failed. ${err.message}`));

      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          disabled: true,
          alert: {
            css: 'alert-success',
            message: `Signed ${type === 'timeIn' ? 'In' : 'Out'} Successfully.`,
          },
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: null,
            code: null,
            role: null,
            alert: null,
            disabled: false,
          },
        });
      }, 1500);
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
  onToggleScanner: (scanner) => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        scanner,
      },
    });
  },
});
