import ajax from 'Shared/Ajax';
import { getUser } from 'Shared/User';

export const stateMapping = state => ({
  loading: state.appState.loading,
  report: state.appState.report,
  project: state.appState.project,
  alert: state.appState.alert,
  role: state.appState.role,
  view: state.appState.view,
  mobile: state.appState.mobile,
  visitor: state.appState.visitor,
  disabled: state.appState.disabled,
});

export const dispatchMapping = dispatch => ({
  showSelector: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        role: null,
      },
    });
  },
  showMobileView: () => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        view: null,
        mobile: null,
      },
    });
  },
  onSubmitMobile: ({ project, report }) => async (mobile) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId } = getUser();
      const visitor = await ajax('get', `projects/${companyId}/${project._id}/site/${report._id}/visitor/?mobile=${mobile}`);

      if (!visitor) {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: 'signup',
            mobile,
            loading: false,
            visitor: {
              mobile,
              name: '',
              businessName: '',
            }
          },
        });
      } else {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: 'visitor',
            mobile,
            visitor,
            loading: false,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
  onSign: ({ project, report, visitor }) => async (type) => {
    try {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: null,
          loading: true,
        },
      });

      const { companyId } = getUser();
      await ajax('put', `projects/${companyId}/${project._id}/site/${report._id}/visitor/${type}`, visitor);

      dispatch({
        type: 'state/UPDATE',
        payload: {
          loading: false,
          disabled: true,
          alert: {
            css: 'alert-success',
            message: `Signed ${type === 'timeIn' ? 'In' : 'Out'} Successfully.`,
          },
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'state/UPDATE',
          payload: {
            view: null,
            mobile: null,
            role: null,
            alert: null,
            disabled: false,
          },
        });
      }, 1500);
    } catch (err) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          alert: {
            css: 'alert-warning',
            message: err.message,
          },
          loading: false,
        },
      });
    }
  },
});
