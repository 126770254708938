import { loadData } from 'Shared/storage';

export const stateMapping = state => ({
  project: state.appState.project,
  site: state.appState.site,
  report: state.appState.report,
  loading: state.appState.loading,
  alert: state.appState.alert,
  page: state.appState.page,
  role: state.appState.role,
});

export const dispatchMapping = dispatch => ({
  loadState: () => {
    const state = loadData('state');

    if (state) {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          ...state,
        },
      });
    } else {
      dispatch({
        type: 'state/UPDATE',
        payload: {
          page: '/'
        },
      });
    }
  },
  onSelectRole: (role) => {
    dispatch({
      type: 'state/UPDATE',
      payload: {
        role,
      },
    });
  },
});
