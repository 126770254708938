import { combineReducers } from 'redux';
import projects from './projects';
import sites from './sites';
import reports from './reports';
import appState from './appState';

export default combineReducers({
  projects,
  sites,
  reports,
  appState,
});
