import React from 'react';
import TextAvatar from 'react-avatar';

const colors = [
  '#204051', '#de7119', '#2b580c', '#084177', '#f76a8c',
  '#7f78d2', '#3b6978', '#721b65', '#d63447', '#0f4c81',
];

const getRandomColor = (colorIndex) => {
  const index = colorIndex
    ? colorIndex % colors.length
    : parseInt(Math.random() * colors.length, 0);

  return colors[index];
};

export default class Avatar extends React.PureComponent {
  render() {
    const {
      src, name, size, round, className, color, fgColor, containMode, colorIndex,
    } = this.props;

    if (src) {
      return (
        <div
          className={className}
          style={{
            borderRadius: round === true ? '100%' : round,
            backgroundImage: `url('${src}')`,
            backgroundSize: containMode ? 'contain' : 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: '#cfd8dc',
            marginTop: '3px',
            width: size,
            height: size,
          }}
        />
      );
    }

    return (
      <TextAvatar
        className={className}
        style={{
          marginTop: '1px',
        }}
        textSizeRatio={2.4}
        name={name}
        size={size}
        round={round}
        color={color || getRandomColor(colorIndex)}
        fgColor={fgColor || '#fff'}
      />
    );
  }
}
