export const saveData = (key, json) => {
  const value = JSON.stringify(json);
  localStorage[key] = value;
};

export const loadData = (key) => {
  const value = localStorage[key];
  if (!value) return null;

  return JSON.parse(value);
};
