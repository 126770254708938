import axios from 'axios';
import { user as initialUser, setUser } from './User';
import config from 'config';

// const { API_URL } = config;
const API_URL='https://pvapp.projectvectors.com.au/api/'
const REQUEST_TIMEOUT = 30000;
let user = initialUser;
let token = user && user.access_token;


// alert(token)
const initAxios = () => {
  axios.defaults.headers = {
    Authorization: token && `Bearer ${token}`,
  };
};
initAxios();

const ajax = {
  get: (path, _, config) => axios.get(API_URL + path, config),
  post: (path, data, config) => axios({
    url: API_URL + path,
    method: 'POST',
    data,
    ...config,
  }),
  put: (path, data, config) => axios({
    url: API_URL + path,
    method: 'PUT',
    data,
    ...config,
  }),
  delete: (path, _, config) => axios.delete(API_URL + path, config),
};

const send = async (func, path, data, config, cancelSource) => {
  const timeout = setTimeout(() => {
    cancelSource.cancel('Request took too long. Please check your connectivity and try again.');
  }, REQUEST_TIMEOUT);

  if (config && config.ignoreTimout) clearTimeout(timeout);

  const result = await func(path, data, { ...config, cancelToken: cancelSource.token });

  clearTimeout(timeout);

  return result;
};

const sendRequest = async (type, path, data, config) => {
  try {
    let newPath = path;
    if (newPath.indexOf(API_URL) === 0) {
      newPath = newPath.substr(API_URL.length);
    }

    const cancelSource = axios.CancelToken.source();
    const resp = await send(ajax[type], newPath, data, config, cancelSource);
    const result = resp.data;

    if (result.status === 200) {
      return result.data;
    }

    throw new Error(result.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      if (type === 'get') {
        // window.location.href = `/auth/login/?returnUrl=${window.location.pathname}`;
        throw new Error('Seems your session has been expired. Redirecting to login page...');
      } else {
        throw new Error('Seems your session has been expired. Please log out and log in again.');
      }
    }
    throw err;
  }
};

export const refresh = async () => {
  const path = 'auth/refresh';

  const result = await sendRequest('get', path);

  setUser(result);
  user = result;
  token = user && user.access_token;
  initAxios();
};

export default sendRequest;
