import React from 'react';

const RoleSelector = ({ user, onSelectRole }) => {
  return (
    <div className="d-flex flex-fill justify-content-around flex-column align-items-center container padding-bottom-xl">
      <button
        type="button"
        className="btn btn-lg btn-primary btn-block padding-top-xl padding-bottom-xl w-75"
        style={{ fontSize: '2rem' }}
        onClick={() => onSelectRole('contractor')}
      >
        {user && user.company.businessName}
      </button>

      <button
        type="button"
        className="btn btn-lg btn-success btn-block padding-top-xl padding-bottom-xl w-75"
        style={{ fontSize: '2rem' }}
        onClick={() => onSelectRole('subbie')}
      >
        {'Sub Contractor'}
      </button>

      <button
        type="button"
        className="btn btn-lg btn-danger btn-block padding-top-xl padding-bottom-xl w-75"
        style={{ fontSize: '2rem' }}
        onClick={() => onSelectRole('visitor')}
      >
        {'Visitor'}
      </button>

      <button
        type="button"
        className="btn btn-lg btn-secondary btn-block padding-top-xl padding-bottom-xl w-75"
        style={{ fontSize: '2rem' }}
        onClick={() => onSelectRole('tools')}
      >
        {'Tools'}
      </button>
    </div>
  );
};

export default RoleSelector;