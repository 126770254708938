import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Alert from 'Shared/Alert';
import Avatar from 'Shared/Avatar';

import { stateMapping, dispatchMapping } from './mapping';
let timer = null;

const ToolView = (props) => {
  const { tool, onSign, showScanView, alert, disabled } = props;
  const [now, updateNow] = useState(new Date().toTimeString().substr(0, 8));

  useEffect(() => {
    if (!timer) {
      timer = setInterval(() => {
        updateNow(new Date().toTimeString().substr(0, 8));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
      timer = null;
    };
  });

  return (
    <div className="d-flex flex-fill justify-content-around flex-column align-items-center padding-bottom-xl">
      <div className="container">
        <div className="card shadow">
          <div className="card-header">
            <h2>Tool</h2>
          </div>
          <div className="card-body text-center">
            <Alert alert={alert} />
            {tool && (
              <>
                <div className="padding-md">
                  <Avatar
                    src={tool.photo && tool.photo.url}
                    className="d-block m-auto"
                    size={120}
                    round
                    name={tool.name}
                  />
                </div>
                <div className="padding-md">
                  <h1 style={{ fontSize: '2.5rem' }}>{tool.name}</h1>
                </div>
                <div className="text-middle-light">
                  <h2>{tool.businessName}</h2>
                </div>

                <div>
                  <h2>{`Time In: ${tool.timeIn || now}`}</h2>
                </div>
                {tool.timeIn && (
                  <div>
                    <h2>{`Time Out: ${now}`}</h2>
                  </div>
                )}
              </>
            )}

            <div className="push-top-lg text-center">
              {tool && !tool.timeOut && (
                <button
                  type="button"
                  className="btn btn-primary btn-wide w-50 m-auto"
                  onClick={() => tool.timeIn ? onSign(props)('timeOut') : onSign(props)('timeIn')}
                  disabled={!tool || disabled}
                >
                  {tool.timeIn ? 'Sign Out' : 'Sign In'}
                </button>
              )}
              {tool && tool.timeOut && (
                <button
                  type="button"
                  className="btn btn-light btn-wide w-50 m-auto"
                  disabled
                >
                  {'Personnel Already Signed Out'}
                </button>
              )}
            </div>
          </div>
          <div className="card-footer text-center">
            <button
              type="button"
              className="btn btn-link text-muted"
              onClick={() => showScanView()}
            >
              {'Go back'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(ToolView);
