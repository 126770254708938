import React from 'react';
import { connect } from 'react-redux';

import ScanView from './ScanView';
import ToolView from './ToolView';
import { stateMapping, dispatchMapping } from './mapping';

const Contractor = ({ view }) => {
  return (
    <div className="d-flex flex-fill align-items-center text-center padding-bottom-xl">
      {(!view || view === 'scan') && <ScanView />}
      {view === 'tool' && <ToolView />}
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(Contractor);