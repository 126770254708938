import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Alert from 'Shared/Alert';

import { stateMapping, dispatchMapping } from './mapping';
let timer = null;

const MobileView = (props) => {
  const [mobile, setMobile] = useState('');
  const { onSubmitMobile, showSelector, alert } = props;
  const [now, updateNow] = useState(new Date().toTimeString().substr(0, 8));

  useEffect(() => {
    if (!timer) {
      timer = setInterval(() => {
        updateNow(new Date().toTimeString().substr(0, 8));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
      timer = null;
    };
  });

  return (
    <div className="d-flex flex-fill justify-content-around flex-column align-items-center padding-bottom-xl">
      <div className="container">
        <div className="card shadow">
          <div className="card-header">
            <h2 className="float-right">{now}</h2>
            <h2>Visitor</h2>
          </div>
          <div className="card-body text-center">
            <Alert alert={alert} />
            <div className="push-top-lg">
              <label className="control-label" style={{ fontSize: '1.5rem' }}>
                {'Please enter your mobile number'}
              </label>
              <input
                type="text"
                className="form-control text-center"
                style={{ fontSize: '1.5rem' }}
                value={mobile}
                onChange={e => setMobile(e.target.value)}
              />
            </div>
            <div className="push-top-lg text-center">
              <button
                type="button"
                className="btn btn-primary btn-wide w-50 m-auto"
                onClick={() => onSubmitMobile(props)(mobile)}
                disabled={!mobile}
              >
                {'Submit'}
              </button>
            </div>
          </div>
          <div className="card-footer text-center">
            <button
              type="button"
              className="btn btn-link text-muted"
              onClick={() => showSelector()}
            >
              {'Go back'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(stateMapping, dispatchMapping)(MobileView);
