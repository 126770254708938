const initialState = [];

export default (state = initialState, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case 'projects/LOAD':
      return payload;
    case 'projects/ADD':
      return [...state, payload];
    case 'projects/UPDATE':
      return state.map((project) => (
        project._id === payload._id
          ? payload
          : project
      ));
    case 'projects/DELETE':
      return state.filter((project) => project._id !== payload);
    case 'projects/CLEAR':
      return initialState;
    default:
      return state;
  }
};
