import axios from 'axios';

const FACE_ENDPOINT = 'https://backend-timesheet.projectvectors.com.au';
const FACE_KEY = '406396be9e5f434aa4a003b7e2eb4528';
const REQUEST_TIMEOUT = 30000;

const PV_ENDPOINT="pvapp.projectvectors.com.au/api"
const headers = {
  'Ocp-Apim-Subscription-Key': FACE_KEY,
};

const send = async (request) => {
  try {
    const cancelSource = axios.CancelToken.source();
    // const timeout = setTimeout(() => {
    //   cancelSource.cancel('Request took too long. Please check your connectivity and try again.');
    // }, REQUEST_TIMEOUT);

    const response = await axios({
      ...request,
      // cancelToken: cancelSource.token,
    });

    // clearTimeout(timeout);

    return response;
  } catch (err) {
    const { response } = err;
    if (response) {
      const message = response.data && response.data.error && response.data.error.message;
      throw new Error(`${response.status}-${response.statusText}: ${message || ''}`);
    }
    throw err;
  }
}

export const detect = async (buffer) => {
  const blob = await fetch(buffer).then(res => res.blob());
  const formData=new FormData()
  formData.append("image",blob,'rec_image.png')

  const response = await send({
    method: 'post',
    url: `${FACE_ENDPOINT}/api/faces/recognization`,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'application/octet-stream',
      ignoreTimeout: true,
    },
  });
  return response;
};
 
export const identify = async (faceId, personGroupId) => {
  const response = await send({
    method: 'post',
    url: `https://${FACE_ENDPOINT}/face/v1.0/identify`,
    data: {
      faceIds: [faceId],
      personGroupId,
      maxNumOfCandidatesReturned: 1,
      confidenceThreshold: 0.6,
    },
    headers,
  });

  const face = response.data[0];
  const candidate = face.candidates[0];
  return candidate && candidate.personId;
};

// Person Groups

export const getPersonGroups = async () => {
  const response = await send({
    method: 'get',
    url: `https://${FACE_ENDPOINT}/face/v1.0/persongroups`,
    headers,
  });

  return response.data;
};

export const createPersonGroup = async (personGroupId, name) => {
  await send({
    method: 'put',
    url: `https://${FACE_ENDPOINT}/face/v1.0/persongroups/${personGroupId}`,
    data: {
      name,
      recognitionModel: 'recognition_02',
    },
    headers,
  });
};

export const train = async (personGroupId) => {
  await send({
    method: 'post',
    url: `https://${FACE_ENDPOINT}/face/v1.0/persongroups/${personGroupId}/train`,
    headers,
  });
};


// Persons

export const getPersonList = async (personGroupId) => {
  const response = await send({
    method: 'get',
    url: `https://${PV_ENDPOINT}/c/${personGroupId}/personnel/`,
    headers,
  });

  return response.data;
};

export const getPerson = async (personId, personGroupId) => {
  const response = await send({
    method: 'get',
    url: `https://${PV_ENDPOINT}/c/${personGroupId}/personnel/${personId}`,
    // url: `https://${FACE_ENDPOINT}/face/v1.0/persongroups/${personGroupId}/persons/${personId}`,
    headers,
  });

  return response.data;
};

export const createPerson = async (personGroupId, name) => {
  const response = await send({
    method: 'post',
    url: `https://${FACE_ENDPOINT}/face/v1.0/persongroups/${personGroupId}/persons`,
    data: {
      name,
    },
    headers,
  });

  const { personId } = response.data;
  return personId;
};
export const addFace = async (personId, buffer) => {
  if(!buffer || buffer.byteLength === 0) {
    throw new Error(`Empty buffer for person ${personId}`);
  } 
  const blob = await fetch(buffer).then(res => res.blob());
  const formData=new FormData()
  formData.set("personId",personId)
  formData.append("image",blob,'add_image.png')
  await send({
    method: 'post',
    url: `${FACE_ENDPOINT}/api/faces/register`,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'application/octet-stream',
    },
  });
};


export const verify=async (mobile) => {

  const response = await send({
    method: 'get',
    url: `${FACE_ENDPOINT}/api/personnels/verify-mobile?mobile=${mobile}`,
    headers,
  });
  return response.data;
};
