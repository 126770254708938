import React from 'react';

const icon = ({ css }) => {
  switch (css) {
    case 'alert-info':
      return 'fa-sync rotate';
    case 'alert-warning':
      return 'fa-times-circle';
    case 'alert-danger':
      return 'fa-times-circle';
    case 'alert-success':
      return 'fa-check-circle';
    default:
      return '';
  }
};


class Alert extends React.PureComponent {
  render() {
    const { alert, noMargin, bg } = this.props;

    if (!alert) {
      return <div />;
    }

    const margin = noMargin ? 'no-margin' : 'push-bottom-md push-top-md';
    const css = bg ? `alert-${bg} ${alert.css.replace('alert', 'text').replace('warning', 'danger')}` : alert.css;

    return (
      <div className={`hidden-print alert ${margin} ${css}`}>
        <i className={`fas ${alert.icon || icon(alert)} mr-2`} />
        {alert.message}
      </div>
    );
  }
}

export default Alert;
